.dataAvailabilityDateRangePickerLegend > div {
  display: flex;
  align-items: center;
}

.MuiStack-root.dataAvailabilityDateRangePickerLegend {
  margin-left: 1.5rem;
  padding-bottom: 1rem;
}

.dataAvailabilityDateRangePickerLegendText {
  line-height: normal;
}

.dataAvailabilityLegendItemIdentifier {
  margin-right: 4px;
  height: 9px;
  width: 8px;
}
