$dotColor: hsl(232, 100%, 85%);
$dotSize: 6px;

@keyframes loaderGrowDot {
  0% {
    opacity: 0.7;
    box-shadow: 0px 0px 0px 0px $dotColor;
  }
  25% {
    opacity: 1;
    box-shadow: 0px 0px 0px 4px $dotColor;
  }
  50% {
    opacity: 0.7;
    box-shadow: 0px 0px 0px 0px $dotColor;
  }
  100% {
    opacity: 0.6;
    box-shadow: 0px 0px 0px 0px $dotColor;
  }
}

.rune-ellipsis-loader {
  .ellipsis {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 48px;
    height: 16px;

    .dots {
      position: absolute;

      &:before,
      &:after {
        content: '';
        position: absolute;
      }

      // First dot
      &:before {
        left: -15px;
        top: 0;
        width: $dotSize;
        height: $dotSize;
        background-color: $dotColor;
        border-radius: 5px;
        background-clip: padding-box;
        animation: loaderGrowDot 1s linear 0s infinite;
      }

      // Second dot
      left: 21px;
      top: 5px;
      width: $dotSize;
      height: $dotSize;
      background-color: $dotColor;
      border-radius: 5px;
      background-clip: padding-box;
      animation: loaderGrowDot 1s linear 0.15s infinite;

      // Third dot
      &:after {
        left: 15px;
        top: 0;
        width: $dotSize;
        height: $dotSize;
        background-color: $dotColor;
        border-radius: 5px;
        background-clip: padding-box;
        animation: loaderGrowDot 1s linear 0.3s infinite;
      }
    }
  }
}
