#taskNotifications {
  .DateInput_input__small {
    padding-left: 0;
  }

  .DateInput_input {
    color: #333333;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    width: 150px;
    font-size: 16px;
    font-weight: 400;
  }

  .DateInput_input::placeholder {
    color: #333333;
  }

  .react-time-picker__wrapper {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    width: 150px;
    color: #333333;

    input::placeholder {
      color: #333333;
    }
  }

  #addTime {
    .react-time-picker__wrapper {
      border-bottom: 'none';
    }
  }

  .react-time-picker__inputGroup {
    padding-left: 0;
    font-size: 16px;
  }
}
