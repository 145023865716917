.strivepd-date-range-picker {
  display: flex;
  align-items: center;
}

.strivepd-date-range-picker .DateRangePicker_picker {
  z-index: 1000;
}

.strivepd-date-range-picker .DateRangePickerInput {
  background-color: var(--color-blueberry-500);
  border-radius: 24px;
  color: var(--color-white);
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin: 2px 0;
}
.strivepd-date-range-picker .DateRangePickerInput:hover {
  background-color: var(--color-blueberry-400);
}

.strivepd-date-range-picker.error .DateRangePickerInput {
  background-color: var(--color-red-600);
}
.strivepd-date-range-picker.error .DateRangePickerInput:hover {
  background-color: var(--color-red-400);
}

.strivepd-date-range-picker .DateInput_fang {
  top: 33px !important;
}

.strivepd-date-range-picker .DateRangePicker_picker,
.strivepd-date-range-picker .DayPicker_transitionContainer,
.strivepd-date-range-picker .DayPicker__withBorder {
  border-radius: 20px;
}

.strivepd-date-range-picker .DayPicker__withBorder,
.strivepd-date-range-picker .DateInput_fangStroke {
  stroke: var(--color-grey-200);
}

.strivepd-date-range-picker .CalendarDay,
.strivepd-date-range-picker .CalendarDay:active,
.strivepd-date-range-picker .CalendarDay:hover {
  border: none;
  padding: 0;
  margin: 0;
}

.strivepd-date-range-picker .CalendarDay:hover {
  background: var(--color-primary-200);
  color: var(--color-black);
}

.strivepd-date-range-picker .CalendarDay__selected,
.strivepd-date-range-picker .CalendarDay__selected:active,
.strivepd-date-range-picker .CalendarDay__selected:hover {
  background: var(--color-primary-1000);
  color: var(--color-white);
}

.strivepd-date-range-picker .CalendarDay__highlighted_calendar,
.strivepd-date-range-picker .CalendarDay__hovered_span,
.strivepd-date-range-picker .CalendarDay__hovered_span:hover,
.strivepd-date-range-picker .CalendarDay__hovered_span:active {
  background: var(--color-lime-100);
  color: var(--color-black);
}

.strivepd-date-range-picker .CalendarDay__hovered_offset,
.strivepd-date-range-picker .CalendarDay__hovered_offset:hover,
.strivepd-date-range-picker .CalendarDay__hovered_offset:active {
  background: var(--color-primary-200);
  color: var(--color-black);
}

.strivepd-date-range-picker .DateInput {
  background: transparent;
}

.strivepd-date-range-picker .DateRangePickerInput_calendarIcon {
  padding: 0;
  margin-left: 5px;
}

.strivepd-date-range-picker .DateRangePickerInput_calendarIcon svg {
  vertical-align: middle;
}

.strivepd-date-range-picker .DateInput:first-of-type .DateInput_input {
  text-align: right;
}

.strivepd-date-range-picker .DateInput__small {
  width: 88px;
}

.strivepd-date-range-picker .DateInput_input,
.strivepd-date-range-picker .DateInput_input::placeholder {
  color: var(--color-white);
  background: transparent;
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  padding: 0;
}
.strivepd-date-range-picker .DateInput_input::placeholder {
  color: var(--color-white);
}

.strivepd-date-range-picker .DateRangePickerInput_arrow {
  color: var(--color-white);
  margin: 0 5px;
}

.strivepd-date-range-picker .DateInput_input__focused {
  border-bottom: 2px solid var(--color-white);
}

.strivepd-date-range-picker tr {
  line-height: 2rem;
}

.strivepd-date-range-picker .error-message {
  color: var(--color-red-600);
  margin-left: 1.5rem;
  padding-bottom: 1rem;
}

.dayContents {
  height: 3px;
}

.dataAvailabilityDateRangePickerTremorDyskinesia {
  background-color: var(--color-primary-1200);
}

.dataAvailabilityDateRangePickerSleep {
  background-color: var(--color-primary-400);
}

.dataAvailabilityDateRangePickerMobility {
  background-color: var(--color-primary-700);
}

.dataAvailabilityDateRangePickerNoData {
  background-color: var(--color-grey-200);
}
