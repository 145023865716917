/* This file contains styles that cannot be overriden by the AmplifyThemeOverrides file. */

[data-amplify-authenticator] [data-amplify-router] {
  border-color: var(--color-grey-200);
  box-shadow: 0px 2px 6px var(--color-grey-300);
}

.amplify-button--link {
  font-weight: 400;
}
.amplify-button--link:hover {
  text-decoration: underline;
}

.duo-sign-in-button {
  background-color: var(--color-white);
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}
.duo-sign-in-button:active,
.duo-sign-in-button:focus,
.duo-sign-in-button:hover {
  background-color: var(--color-primary-200);
  border-color: var(--color-primary-1000);
}
