:root {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-primary-200: #eff7fd;
  --color-primary-300: #dbeefb;
  --color-primary-400: #c4e2f9;
  --color-primary-500: #a9d5f5;
  --color-primary-600: #8cc6f0;
  --color-primary-700: #74b4e2;
  --color-primary-800: #5b9fd0;
  --color-primary-900: #438dc2;
  --color-primary-1000: #006ebd;
  --color-primary-1100: #0063aa;
  --color-primary-1200: #005694;
  --color-primary-1300: #004d84;
  --color-primary-1400: #004271;
  --color-primary-1500: #00375f;
  --color-primary-1600: #002c4c;
  --color-teal-100: #d9f4ed;
  --color-teal-200: #b3e9db;
  --color-teal-300: #8cddc8;
  --color-teal-400: #66d2b6;
  --color-teal-500: #40c7a4;
  --color-teal-600: #3ab394;
  --color-teal-700: #339f83;
  --color-teal-800: #2d8b73;
  --color-teal-900: #267762;
  --color-bondi-100: #ccecf1;
  --color-bondi-200: #99d9e4;
  --color-bondi-300: #66c6d6;
  --color-bondi-400: #33b3c9;
  --color-bondi-500: #00a0bb;
  --color-bondi-600: #0090a8;
  --color-bondi-700: #008096;
  --color-bondi-800: #007083;
  --color-bondi-900: #006070;
  --color-tremor-100: #dfe9fe;
  --color-tremor-200: #bed4fc;
  --color-tremor-300: #9ebefb;
  --color-tremor-400: #7da9f9;
  --color-tremor-500: #5d93f8;
  --color-tremor-600: #5484df;
  --color-tremor-700: #4a76c6;
  --color-tremor-800: #4167ae;
  --color-tremor-900: #385895;
  --color-dyskinesia-100: #d6ecd5;
  --color-dyskinesia-200: #add9ab;
  --color-dyskinesia-300: #85c680;
  --color-dyskinesia-400: #5cb356;
  --color-dyskinesia-500: #33a02c;
  --color-dyskinesia-600: #2e9028;
  --color-dyskinesia-700: #298023;
  --color-dyskinesia-800: #24701f;
  --color-dyskinesia-900: #1f601a;
  --color-grey-100: #fdfdfd;
  --color-grey-200: #efefef;
  --color-grey-300: #dedede;
  --color-grey-400: #cbcbcb;
  --color-grey-500: #acacac;
  --color-grey-600: #939393;
  --color-grey-700: #777777;
  --color-grey-800: #5b5b5b;
  --color-grey-900: #333333;
  --color-cool-50: #f9fbff;
  --color-cool-100: #eff4fa;
  --color-cool-200: #e4e9ef;
  --color-cool-300: #d8dce0;
  --color-cool-400: #c2c7cc;
  --color-cool-500: #a2a8ad;
  --color-cool-600: #8b9093;
  --color-cool-700: #717578;
  --color-cool-800: #55595b;
  --color-cool-900: #2c3033;
  --color-warm-100: #faf9f8;
  --color-warm-200: #eeecea;
  --color-warm-300: #e0ddda;
  --color-warm-400: #cdc9c5;
  --color-warm-500: #adaaa6;
  --color-warm-600: #95918c;
  --color-warm-700: #797571;
  --color-warm-800: #5e5b58;
  --color-warm-900: #373431;
  --color-orange-100: #ffdacd;
  --color-orange-200: #ffb49b;
  --color-orange-300: #ff8f69;
  --color-orange-400: #ff6937;
  --color-orange-500: #ff4405;
  --color-orange-600: #e63d05;
  --color-orange-700: #cc3604;
  --color-orange-800: #b33004;
  --color-orange-900: #992903;
  --color-yellow-100: #fff5d2;
  --color-yellow-200: #ffeba5;
  --color-yellow-300: #ffe178;
  --color-yellow-400: #ffd74b;
  --color-yellow-500: #ffcd1e;
  --color-yellow-600: #e6b91b;
  --color-yellow-700: #cca418;
  --color-yellow-800: #b39015;
  --color-yellow-900: #997b12;
  --color-lime-100: #e0f4d2;
  --color-lime-200: #c2e8a4;
  --color-lime-300: #a3dd77;
  --color-lime-400: #85d149;
  --color-lime-500: #66c61c;
  --color-lime-600: #5cb219;
  --color-lime-700: #529e16;
  --color-lime-800: #478b14;
  --color-lime-900: #3d7711;
  --color-green-100: #d0f1e1;
  --color-green-200: #a0e2c3;
  --color-green-300: #71d4a6;
  --color-green-400: #41c588;
  --color-green-500: #12b76a;
  --color-green-600: #10a55f;
  --color-green-700: #0e9255;
  --color-green-800: #0d804a;
  --color-green-900: #0b6e40;
  --color-cyan-100: #d8f3f7;
  --color-cyan-200: #b2e7ef;
  --color-cyan-300: #8bdbe6;
  --color-cyan-400: #65cfde;
  --color-cyan-500: #3ec3d6;
  --color-cyan-600: #38b0c1;
  --color-cyan-700: #329cab;
  --color-cyan-800: #2b8996;
  --color-cyan-900: #257580;
  --color-blueberry-100: #d6d7f0;
  --color-blueberry-200: #adafe0;
  --color-blueberry-300: #8588d1;
  --color-blueberry-400: #5c60c1;
  --color-blueberry-500: #3338b2;
  --color-blueberry-600: #2e32a0;
  --color-blueberry-700: #292d8e;
  --color-blueberry-800: #24277d;
  --color-blueberry-900: #1f226b;
  --color-violet-100: #e7deff;
  --color-violet-200: #cfbdff;
  --color-violet-300: #b69dff;
  --color-violet-400: #9e7cff;
  --color-violet-500: #865bff;
  --color-violet-600: #7952e6;
  --color-violet-700: #6b49cc;
  --color-violet-800: #5e40b3;
  --color-violet-900: #503799;
  --color-purple-100: #f3defd;
  --color-purple-200: #e7bdfb;
  --color-purple-300: #dc9cfa;
  --color-purple-400: #d07bf8;
  --color-purple-500: #c45af6;
  --color-purple-600: #b051dd;
  --color-purple-700: #9d48c5;
  --color-purple-800: #893fac;
  --color-purple-900: #763694;
  --color-pink-100: #ffe6f9;
  --color-pink-200: #ffcdf4;
  --color-pink-300: #ffb4ee;
  --color-pink-400: #ff9be9;
  --color-pink-500: #ff82e3;
  --color-pink-600: #e675cc;
  --color-pink-700: #cc68b6;
  --color-pink-800: #b35b9f;
  --color-pink-900: #994e88;
  --color-red-100: #f3ccd0;
  --color-red-200: #e699a1;
  --color-red-300: #d25852;
  --color-red-400: #cd3342;
  --color-red-500: #c10013;
  --color-red-600: #ae0011;
  --color-red-700: #9a000f;
  --color-red-800: #87000d;
  --color-red-900: #74000b;
}
